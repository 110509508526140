import React from "react"
import { Container, Grid, Hidden } from "@material-ui/core"
import Layout from "../../components/Layout/layout"
import PageInfoSection from "../../components/PageInfoSection/PageInfoSection"
import Squares from '../../components/Squares/Squares'
import { services } from '../../components/ServicesSection/services'
import ServiceItem from '../../components/ServicesSection/components/ServiceItem'
import Carousel from "react-material-ui-carousel"
const ServicesPage = () => (
  <Layout style={{ overflowX: 'hidden' }}>
    <Squares>
      <Container>
        <PageInfoSection
          title="our"
          blueTitle="services"
          text="We help our clients expand their businesses. At Webfluent, we are passionate about our customers and take an individualistic approach when assessing your needs. Whether you need an entirely new website built from scratch, a ‘refresh’ of a current site, or an app developed to boost your sales, your project has our full attention."
        >
          <Grid spacing={6} container align='center' justify='center'>
            <Hidden mdDown>
              {services.map((i, ind) => (
                <Grid align='center' item md={6}>
                  <ServiceItem img={i.img} text={i.text} name={i.name} bolded={i.bolded} />
                </Grid>
              ))}
            </Hidden>
            <Hidden lgUp>
              <Carousel>
                {services.map((i, ind) => (
                  <Grid align='center' item md={6}>
                    <ServiceItem img={i.img} text={i.text} name={i.name} bolded={i.bolded} />
                  </Grid>
                ))}
              </Carousel>
            </Hidden>
          </Grid>
        </PageInfoSection>
      </Container>
    </Squares>
  </Layout>
)

export default ServicesPage
